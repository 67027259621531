<template>
  <div class="backdrop edit_entrance" @click.self="closeModal">
    <div class="modal-wrapper entrance-info">
      <h2 v-if="!isEditing" class="entrance-title" @click="startEditing">{{entrance.entrance}} подъезд</h2>
      <input
          v-else-if="isEditing"
          type="text"
          class="form-control"
          v-model="newTitle"
          @blur="finishEditing"
          ref="newTask"

      />
      <div v-for="(floor, key) in aptMatrix" :key="key" class="floor-item">
        <div class="floor">{{ key }} этаж</div>
        <div class="flat-row">

          <div
              v-for="apt in floor"
              :key="apt.apartment_id"
              class="flat-num"
              :class="{
              'grey': !!apt.personal_account
            }"
          >{{ apt.number }}
          </div>
            <div class="plus-btn flat-num" @click="addApartament">+</div>
            <div class="plus-btn" @click="delFloor()">-</div>
          </div>
      </div>

      <div v-for="newFloor in newFloorArr" class="floor-item" >
        <div class="floor">{{newFloor}} этаж</div>
        <div class="plus-btn" @click="delFloorArr(parseInt(newFloor))">-</div>
      </div>

        <div class="plus-btn" @click="addFloor">+</div>
      <div class="btn-row">
        <div class="decline-btn" @click="closeModal">Отмена</div>
        <div class="add-btn" @click="editItem">Сохранить</div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  props: {
    aptMatrix :{
      type: Object,
      default: () => {},
    },
    entrance: {
      type: Object,
      default: () => {},
    },
    block: {
      type: Object,
      default: () => {},
    },
  },
  data(){
    return {
      isEditing: false,
      newTitle:"",
      newFloorCnt: 0,
      newFloorArr:[],
    }
  },
  mounted() {
    this.floorCnt();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    editItem() {
      this.closeModal();
    },
    addFloor(){
      this.newFloorCnt++;
      this.newFloorArr.push(this.newFloorCnt);
      console.log(this.newFloorCnt)
    },
    addApartament(){

    },
    delFloorArr(e){
      for (let i = 0;i<this.newFloorArr.length+1;i++){
        if(this.newFloorArr[i]==e){
          this.newFloorArr.splice(i,1);
          console.log(e);
          this.newFloorCnt--;
          break;
        }
      }
    },
    delFloor(){
      this.$emit('deleteFloor');
    },
    floorCnt(){
      this.newFloorCnt = parseInt(Object.keys(this.aptMatrix)[Object.keys(this.aptMatrix).length-1]);
    }
    ,
    startEditing(){
      if(this.isEditing){
        this.finishEditing();
      }else{
        this.newTitle = this.title;
        this.isEditing = true;
        this.$nextTick(() => this.$refs.newTask.focus());
      }
    },
    finishEditing() {
      this.isEditing = false;
      // this.$emit("on-edit", this.newTitle);
      this.computedTitle = this.newTitle;
    },
  },
};
</script>

<style>
.plus-btn{
  margin-left: 10%;
  padding-bottom: 10px;
  font-size: 40px;
  cursor: pointer;
  /*background: #5B6CFF;*/
  width: 6%;
}
.modal-wrapper .btn-row{
  padding: 15px;
}

</style>